import selectGetters from "@/store/templates/select/getters";

export default {
  ...selectGetters,
  sectors: (state, getters) =>
    getters.items.filter(item => item.type === "SECTOR"),
  subsectors: (state, getters) =>
    getters.items.filter(item => item.type === "SUBSECTOR"),
  jobs: (state, getters) => getters.items.filter(item => item.type === "JOB"),
  subjectName: state => id => state.entities.entity[id].name
};
